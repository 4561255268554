
import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout/layout';
import '../stylesheets/templates/single-post.scss';
import htmlSerializer from '../utilities/htmlSerializer';
import { RichText } from 'prismic-reactjs';
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon } from 'react-share';

const TextContent = (content) => {

  let textClass = "text-content";
  if (content.isFirstText) textClass = "text-content first";

  return (
    <div className={textClass}>
      <RichText render={content.content} htmlSerializer={htmlSerializer} />
    </div>
  );
}

const ImageContent = (content) => {

  let imageClass = "image-content py-3";
  if (content.content.image_position === 'Float left') {
    imageClass = "image-content p-5 float-start";
  } else if (content.content.image_position === 'Float right') {
    imageClass = "image-content p-5 float-end";
  }

  return (
    <div className={imageClass}>
      {content.content.image_link.url ? <a href={content.content.image_link.url} target={content.content.image_link.target}><img src={content.content.image.url} alt={content.content.image.alt} /></a> : <img src={content.content.image.url} alt={content.content.image.alt} />}
    </div>
  );
}


const PostTemplate = ({
  data: { post, headerData, footerData },
  pageContext,
  Location,
}) => {

  let countText = 0;
  let isFirstText = false;

  const singlePost = post.data;
  const header = headerData.data;
  const footer = footerData.data;

  // sharing
  const shareUrl = `https://sentier.com/` + post.url;
  const shareTitle = singlePost.blog_post_title.text + ` | Sentier Blog`;
  const shareDescription = singlePost.short_description
    ? singlePost.short_description.text
    : singlePost.meta_description.text;

  return (
    <>
      <Layout headerStyle="light" title={singlePost.meta_title.text} description={singlePost.meta_description.text} socialImage={singlePost.featured_image_blog_post.url} header={header} footer={footer} >
        <div className="single-post">
          <div className="container">

            <div className="featured-image">
              <img src={singlePost.featured_image_blog_post.url} alt={singlePost.featured_image_blog_post.alt} />
            </div>

            <h2 className="blog-post-title">{singlePost.blog_post_title.text}</h2>

            <div className="post-data">
              <p>
                <span className="post-author">{singlePost.author.text}</span>
                <span className="post-date">{singlePost.post_date}</span>
              </p>
            </div>

            <div className="blog-body">

              {singlePost.body.map((slice, index) => {

                countText++;

                if (countText === 1) {
                  isFirstText = true;
                } else {
                  isFirstText = false;
                }

                switch (slice.slice_type) {
                  case 'text':
                    return <TextContent key={index} content={slice.primary.text.richText} isFirstText={isFirstText} />
                  case 'image_gallery':
                    return <ImageContent key={index} content={slice.primary} />
                  default:
                    return null;
                };

              })}

            </div>
            <div className="social-share">
              <h6 className="orange-text">Please share!</h6>
              <div className="social-icons pt-3 d-flex flex-wrap">
                <div className="social-icon twitter">
                  <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size={72} round={true} iconFillColor="#121112" />
                  </TwitterShareButton>
                </div>
                <div className="social-icon facebook">
                  <FacebookShareButton url={shareUrl} quote={shareTitle}>
                    <FacebookIcon size={52} round={true} />
                  </FacebookShareButton>
                </div>
                <div className="social-icon linkedin">
                  <LinkedinShareButton url={shareUrl} source={shareUrl} title={shareTitle} summary={shareDescription} sizde={52}>
                    <LinkedinIcon round={false} size={52} />
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="black-line"></div>
      </Layout>
    </>
  )
}
export default PostTemplate

export const postQuery = graphql`
  query Post($slug: String) {
    post: prismicPost(uid: { eq: $slug }) {
      uid
      url
      data {
        blog_post_title {
          text
        }
        categories {
          category
        }
        excerpt {
          html
          richText
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        post_date(formatString: "MM/DD/yyyy")
        featured_image_blog_post {
          alt
          url
        }
        featured_image_excerpt {
          alt
          url
        }
        body {
          ... on PrismicPostDataBodyText {
            id
            slice_type
            primary {
              text {
                richText
                html
              }
            }
          }
          ... on PrismicPostDataBodyImageGallery {
            id
            slice_type
            primary {
              image {
                alt
                url
              }
              image_link {
                url
                target
                type
              }
              image_position
            }
          }
        }
        author {
          text
        }
      }

  }

  headerData: prismicNavigation {
    ...FragmentHeader
  }

  footerData: prismicFooter {
    ...FragmentFooter
  }
}
`